<template>
  <div class="zem-input-container tw-relative">
    <input
      :class="[inputClass]"
      :disabled="disabled"
      :maxlength="maxLength"
      :placeholder="placeholder"
      :required="required"
      :type="type"
      :value="`${suffix ? ` ${suffix}` : value}`"
      class="zem-input tw-text-center"
    />
    <button
      class="tw-absolute tw-top-2 tw-left-2 tw-p-[1px] tw-rounded-full tw-bg-[#0DB2B2]"
      @click="updateCounter('-')"
    >
      <svg fill="none" height="8" viewBox="0 0 8 8" width="8" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.666016 3.99989C0.666016 3.69307 0.914746 3.44434 1.22157 3.44434H6.77713C7.08395 3.44434 7.33268 3.69307 7.33268 3.99989C7.33268 4.30672 7.08395 4.55545 6.77713 4.55545H1.22157C0.914746 4.55545 0.666016 4.30672 0.666016 3.99989Z"
          fill="white"
        />
      </svg>
    </button>
    <button
      class="tw-absolute tw-top-2 tw-right-2 tw-p-[1px] tw-rounded-full tw-bg-[#0DB2B2]"
      @click="updateCounter('+')"
    >
      <svg fill="none" height="8" viewBox="0 0 8 8" width="8" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="M4.55488 1.2223C4.55488 0.915479 4.30615 0.666748 3.99932 0.666748C3.6925 0.666748 3.44377 0.915478 3.44377 1.2223V3.44448H1.22157C0.914746 3.44448 0.666016 3.69321 0.666016 4.00003C0.666016 4.30686 0.914746 4.55559 1.22157 4.55559H3.44377V6.77786C3.44377 7.08468 3.6925 7.33342 3.99932 7.33342C4.30615 7.33342 4.55488 7.08468 4.55488 6.77786V4.55559H6.77713C7.08395 4.55559 7.33268 4.30686 7.33268 4.00003C7.33268 3.69321 7.08395 3.44448 6.77713 3.44448H4.55488V1.2223Z"
          fill="white"
          fill-rule="evenodd"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import {formattedPhone, phoneTrim} from '@/assets/scripts/scripts'

export default {
  name: 'ZemInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 9999999,
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: '',
    },
    integer: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputClass() {
      if (this.isCompact) {
        return 'zem-input--compact'
      }
      return ''
    },
  },
  mounted() {
    if (this.mask === 'phone') {
      this.phoneInput()
    }
    if (this.integer) {
      this.integerInput()
    }
  },
  methods: {
    updateCounter(type) {
      if (type === '-') {
        if (this.value > 0) {
          this.$emit('decrement')
        }
      } else {
        this.$emit('increment')
      }
      this.$emit('update', type)
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-input {
  padding: 5px 22px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  color: $color-mine-shaft;
  border: 1px solid $color-catskill-grey;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  min-height: 26px;

  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
  }

  &:focus {
    border: 1px solid $color-accent;
  }

  &--compact {
    margin: 0;
    height: 25px;
  }

  &.error {
    border: 1px solid $color-red;
  }

  &__error {
    color: $color-red;
    font-size: 10px;
    margin-left: 10px;
  }

  &-container {
    width: 100%;

    &.mt-0 {
      .zem-input {
        margin-top: 0;
      }
    }
  }

  &.title-input {
    margin: 0;
  }
}

label {
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: $color-mine-shaft;
  text-align: left;
  margin-bottom: 3px;
}
</style>
